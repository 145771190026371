export default {
  v: '5.9.6',
  fr: 30,
  ip: 0,
  op: 39,
  w: 33,
  h: 43,
  nm: 'Json',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Ellipse 432',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 14,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 22,
              s: [100]
            },
            { t: 30, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: -90, ix: 10 },
        p: { a: 0, k: [15, 14, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [24, 24], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 432',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 50, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        },
        {
          ty: 'st',
          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false
        }
      ],
      ip: 14,
      op: 9913,
      st: 14,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Ellipse 431',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 8,
              s: [0]
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 16,
              s: [100]
            },
            { t: 24, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: -90, ix: 10 },
        p: { a: 0, k: [15, 15, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [16, 16], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Ellipse 431',
          np: 1,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 50, ix: 2 },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false
        },
        {
          ty: 'st',
          c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
          o: { a: 0, k: 100, ix: 4 },
          w: { a: 0, k: 2, ix: 5 },
          lc: 1,
          lj: 1,
          ml: 4,
          bm: 0,
          nm: 'Stroke 1',
          mn: 'ADBE Vector Graphic - Stroke',
          hd: false
        }
      ],
      ip: 8,
      op: 9907,
      st: 8,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Vector',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [15, 14, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-3, -12.966, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0], y: [1, 1, 1] },
              o: { x: [0.36, 0.36, 0.36], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100]
            },
            {
              i: { x: [0, 0, 0], y: [1, 1, 1] },
              o: { x: [0.36, 0.36, 0.36], y: [0, 0, 0] },
              t: 15,
              s: [69, 69, 100]
            },
            { t: 30, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.503, 0],
                    [0, -1.798],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.002],
                    [0, 0],
                    [1.614, -1.599],
                    [2.272, 0],
                    [0, 0],
                    [1.337, 0.83],
                    [0.685, 1.417],
                    [0.029, 0.062],
                    [0.042, 0.077],
                    [-0.448, 0.28],
                    [0, 0],
                    [-1.153, -0.266],
                    [-0.627, -0.99],
                    [0, 0],
                    [-1.734, 0],
                    [0, -1.734],
                    [0, 0],
                    [-0.436, 0],
                    [-0.573, -0.94],
                    [-0.618, -0.001],
                    [-0.443, -1.276]
                  ],
                  o: [
                    [1.798, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.002],
                    [0, 0],
                    [-0.021, 2.272],
                    [-1.614, 1.599],
                    [0, 0],
                    [-1.578, 0],
                    [-1.345, -0.835],
                    [-0.029, -0.06],
                    [-1.701, -3.645],
                    [-0.255, -0.463],
                    [0, 0],
                    [1.003, -0.628],
                    [1.143, 0.264],
                    [0, 0],
                    [0, -1.734],
                    [1.734, 0],
                    [0, 0],
                    [0.402, -0.17],
                    [1.179, 0],
                    [0.521, -0.332],
                    [1.425, 0],
                    [0.45, -0.225]
                  ],
                  v: [
                    [10.431, -5.33],
                    [13.693, -2.069],
                    [13.693, -2.069],
                    [13.693, -0.396],
                    [13.692, -0.391],
                    [13.693, -0.386],
                    [13.627, 6.552],
                    [11.091, 12.554],
                    [5.065, 15.034],
                    [0.015, 15.034],
                    [-4.442, 13.765],
                    [-7.545, 10.323],
                    [-7.633, 10.139],
                    [-13.569, -1.204],
                    [-13.225, -2.528],
                    [-12.278, -3.121],
                    [-8.934, -3.683],
                    [-6.19, -1.74],
                    [-6.19, -11.889],
                    [-3.045, -15.034],
                    [0.1, -11.889],
                    [0.1, -7.989],
                    [1.37, -8.246],
                    [4.157, -6.676],
                    [5.9, -7.184],
                    [8.982, -4.989]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.239, 1.227],
                    [-0.017, 1.743],
                    [0, 0],
                    [0, 0],
                    [0.7, 0],
                    [0, -0.7],
                    [0, 0],
                    [0.55, 0],
                    [0, 0.55],
                    [0, 0],
                    [0.7, 0],
                    [0, -0.7],
                    [0, 0],
                    [0.55, 0],
                    [0, 0.55],
                    [0, 0],
                    [0.7, 0],
                    [0, -0.7],
                    [0, 0],
                    [0.55, 0],
                    [0, 0.55],
                    [0, 0],
                    [0.636, 0],
                    [0, -0.636],
                    [0, 0],
                    [0.13, -0.173],
                    [0.208, -0.06],
                    [0.202, 0.078],
                    [0.115, 0.184],
                    [0, 0],
                    [0.634, 0.146],
                    [0.552, -0.346],
                    [0, 0],
                    [-1.412, -3.025],
                    [-0.025, -0.053],
                    [-2.45, 0]
                  ],
                  o: [
                    [1.743, 0],
                    [1.239, -1.227],
                    [0, 0],
                    [0, 0],
                    [0, -0.7],
                    [-0.7, 0],
                    [0, 0],
                    [0, 0.55],
                    [-0.55, 0],
                    [0, 0],
                    [0, -0.7],
                    [-0.7, 0],
                    [0, 0],
                    [0, 0.55],
                    [-0.55, 0],
                    [0, 0],
                    [0, -0.7],
                    [-0.7, 0],
                    [0, 0],
                    [0, 0.55],
                    [-0.55, 0],
                    [0, 0],
                    [0, -0.636],
                    [-0.636, 0],
                    [0, 0],
                    [0, 0.216],
                    [-0.13, 0.173],
                    [-0.208, 0.06],
                    [-0.202, -0.078],
                    [0, 0],
                    [-0.345, -0.552],
                    [-0.634, -0.147],
                    [0, 0],
                    [1.143, 2.094],
                    [0.025, 0.054],
                    [1.053, 2.178],
                    [0, 0]
                  ],
                  v: [
                    [5.065, 13.042],
                    [9.69, 11.139],
                    [11.636, 6.533],
                    [11.701, -0.401],
                    [11.701, -2.069],
                    [10.432, -3.338],
                    [9.162, -2.069],
                    [9.162, -0.396],
                    [8.166, 0.6],
                    [7.17, -0.396],
                    [7.17, -3.922],
                    [5.901, -5.192],
                    [4.631, -3.922],
                    [4.631, -0.396],
                    [3.635, 0.6],
                    [2.639, -0.396],
                    [2.639, -4.984],
                    [1.37, -6.254],
                    [0.1, -4.984],
                    [0.1, -0.396],
                    [-0.896, 0.6],
                    [-1.892, -0.396],
                    [-1.892, -11.889],
                    [-3.045, -13.042],
                    [-4.199, -11.889],
                    [-4.199, 1.738],
                    [-4.399, 2.337],
                    [-4.92, 2.695],
                    [-5.552, 2.667],
                    [-6.039, 2.265],
                    [-7.863, -0.658],
                    [-9.381, -1.742],
                    [-11.221, -1.433],
                    [-11.372, -1.339],
                    [-5.828, 9.296],
                    [-5.752, 9.456],
                    [0.015, 13.042]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Vector',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 9899,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
